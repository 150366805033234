import React, { useState } from 'react';
import { Popover, styled, TextField } from '@mui/material';
import { ChromePicker } from 'react-color';
import InputAdornment from '@mui/material/InputAdornment';
import CircleIcon from '@mui/icons-material/Lens';
import { Box, SxProps } from '@mui/system';

const StyChromePicker = styled(ChromePicker, {
    name: 'StyChromePicker',
})(() => ({
    boxShadow: 'none !important',
}));

type Props = {
    value?: string;
    setValue: (value: string) => void;
    label: string | null;
    sx?: SxProps;
    error?: string;
};

const ColorPicker = ({ value, setValue, label, sx, error }: Props) => {
    const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);
    const open = Boolean(anchorEl);
    const handleClick = (
        event: React.MouseEvent<SVGSVGElement, MouseEvent>,
    ) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const id = open ? 'simple-popover' : undefined;
    return (
        <Box sx={sx}>
            <TextField
                value={value}
                onChange={(e) => setValue(e.target.value)}
                variant="outlined"
                label={label}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <CircleIcon
                                sx={{ color: value }}
                                onClick={handleClick}
                            />
                        </InputAdornment>
                    ),
                }}
                error={!!error}
                helperText={error}
            />
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
            >
                <StyChromePicker
                    color={value}
                    onChange={(color) => setValue(color.hex)}
                />
            </Popover>
        </Box>
    );
};

export default React.memo(ColorPicker);
